class Cook {
  constructor(title, time, img) {
    this.title = title;
    this.time = time;
    this.img = img;
  }
}
const cookList = [
  new Cook("苦瓜炒猪心", "2020.06.21", require("./IMG_20200621_222629.jpg")),
  new Cook("西红柿炒牛肉", "2020.06.26", require("./IMG_20200626_232654.jpg")),
  new Cook("西红柿炒猪肉", "2020.07.05", require("./IMG_20200705_221807.jpg")),
  new Cook("哈密瓜", "2020.07.07", require("./IMG_20200707_232854.jpg")),
  new Cook("大葱炒鸭肾", "2020.07.12", require("./IMG_20200712_152749.jpg")),
  new Cook("胡萝卜炒鸡肉", "2020.07.12", require("./IMG_20200712_204942.jpg")),
  new Cook("葡萄", "2020.07.14", require("./IMG_20200714_001241.jpg")),
  new Cook("胡萝卜炒鸭肾", "2020.07.17", require("./IMG_20200717_223640.jpg")),
  new Cook("芒果", "2020.07.18", require("./IMG_20200718_012332.jpg")),
  new Cook("蕹菜炒猪肉", "2020.07.19", require("./IMG_20200719_132350.jpg")),
  new Cook("小白菜炒鸭肾", "2020.07.26", require("./IMG_20200726_142827.jpg")),
  new Cook("生菜炒猪肉", "2020.07.26", require("./IMG_20200726_203249.jpg")),
  new Cook("胡萝卜炒猪肉", "2020.07.31", require("./IMG_20200731_202616.jpg")),
  new Cook("茄子炒猪肉", "2020.08.02", require("./IMG_20200801_203344.jpg")),
  new Cook("白菜炒牛肉", "2020.08.02", require("./IMG_20200802_153612.jpg"))
];
export default cookList;
