<template>
  <div class="cook">
    <div class="search-wrapper">
      <input type="text" v-model="keyword" placeholder="Search title..." />
      <label>Search Title</label>
    </div>
    <div class="wrapper">
      <viewer>
        <div class="card" v-for="Cook in filteredList" :key="Cook.img">
          <a target="_blank">
            <img v-bind:src="Cook.img" height="194" />
            <small>{{ Cook.time }}</small> {{ Cook.title }}
          </a>
        </div>
      </viewer>
    </div>
  </div>
</template>

<script>
import cookList from "../../../assets/cook/cookList";
export default {
  data() {
    return {
      keyword: "",
      cookList: cookList
    };
  },
  methods: {
    toggleOnOff() {
      this.onOff = !this.onOff;
    }
  },
  computed: {
    filteredList() {
      return this.cookList.filter(Cook => {
        return Cook.title.toLowerCase().includes(this.keyword.toLowerCase());
      });
    }
  }
};
</script>
<style lang="scss">
.cook {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50;
  margin: 0 auto;
  .search-wrapper {
    position: relative;
    height: 40px;
    label {
      position: absolute;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      top: 8px;
      left: 12px;
      z-index: -1;
      transition: 0.15s all ease-in-out;
    }
    input {
      padding: 4px 12px;
      color: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.12);
      transition: 0.15s all ease-in-out;
      background: white;
      &:focus {
        outline: none;
        transform: scale(1.05);
        & + label {
          font-size: 10px;
          transform: translateY(-24px) translateX(-12px);
        }
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 100;
      }
    }
  }
  .wrapper {
    width: 100%;
    flex-wrap: wrap;
    .card {
      float: left;
      box-shadow: rgba(0, 0, 0, 0.11765) 0px 1px 6px,
        rgba(0, 0, 0, 0.11765) 0px 1px 4px;
      margin: 12px;
      transition: 0.15s all ease-in-out;
      a {
        text-decoration: none;
        color: #03a9f4;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;
        small {
          font-size: 10px;
          padding: 4px;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
